.bannerWrapper{
  color: white;
  object-fit: contain;
  height: 448px;

}


.wrapper2
{ margin-left: 30px;
  padding-top: 140px;
  height: 190px;
}
.dis{
  width: 45rem;
  line-height: 1.3;
  padding-top: 1rem;
  font-size: 0.8rem;
  max-width: 360px;
  height: 80px;
}
button{
    margin-left: 30px;
    color: white;
    padding: 10px 20px;
    font-size: 20px;
    background-color: rgba(51, 51, 51, 0.5);
    border: none;
}
button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.9s;
  
  }
  .banner__fadeBottom {
    height: 7.4rem;
    background-image: linear-gradient(
      180deg,
      transparent,
      rgb(37, 37, 37, 0.61),
     #111
    );
  }

  