
.row {
    margin-left: 20px;

  }
.movieWarraper{
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;

}
.movieWarraper::-webkit-scrollbar {
    display: none;
  
}
.classNamee {
    width: 100%;
    object-fit: contain;
    max-height: 100px;
    transition: transform 450ms;
       margin-right: 10px;
    
}
.classNamee:hover{
    transform: scale(1.08);

}

.row__posterLarge {
    max-height: 250px;
    width: auto;
    transition: transform 650ms;
  }
  .row__posterLarge:hover {
    transform: scale(1.09);
    opacity: 1;
  }
  