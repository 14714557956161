* {
    margin: 0;
  }
  .App {
    background-color: #111;
    color: white;
  }
  

.App{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   
}

